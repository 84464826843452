import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/06bbdb2039db4d59efb8c9ed70439540/e8277/rr-intro.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHnVSTiYZ//xAAbEAACAQUAAAAAAAAAAAAAAAABAgADBBARIv/aAAgBAQABBQJAwl0wanN8Y//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAaEAACAgMAAAAAAAAAAAAAAAACEQABICGR/9oACAEBAAY/AqJagLuP/8QAGxABAAEFAQAAAAAAAAAAAAAAAREAECExgVH/2gAIAQEAAT8hhiCyS7oaBJv1ZZwHKmbf/9oADAMBAAIAAwAAABDD/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQMBAT8QTCb/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/ENOkP//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQRBRsf/aAAgBAQABPxAczYAI+zFE7uga9B1gk4wqUUsnTgxSq18f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rr-intro",
            "title": "rr-intro",
            "src": "/static/06bbdb2039db4d59efb8c9ed70439540/4b190/rr-intro.jpg",
            "srcSet": ["/static/06bbdb2039db4d59efb8c9ed70439540/e07e9/rr-intro.jpg 200w", "/static/06bbdb2039db4d59efb8c9ed70439540/066f9/rr-intro.jpg 400w", "/static/06bbdb2039db4d59efb8c9ed70439540/4b190/rr-intro.jpg 800w", "/static/06bbdb2039db4d59efb8c9ed70439540/e5166/rr-intro.jpg 1200w", "/static/06bbdb2039db4d59efb8c9ed70439540/b17f8/rr-intro.jpg 1600w", "/static/06bbdb2039db4d59efb8c9ed70439540/e8277/rr-intro.jpg 6016w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`What is the layout and structure of Roam?`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Roam is a bullet point note-taking app at its core. This is not a note-taking app for beginners. You can imagine this as a bullet point note-taker with superpowers. Those super power are that in roam, you can easily make bi-directional links.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`To be clear a lot of people won't have much beneftis if any at all from Roam and Roam may even end up confusing them if they do not use the links efficiently.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`One of the selling points of Roam Research is the Bi-Directional linking. To understand bi-direcitonal linking, let me give you a overview of our brain`}</p>
        <h2 parentName="li">{`Our Brain`}</h2>
        <ul parentName="li">
          <li parentName="ul">{`In our brain, information is `}<strong parentName="li"><em parentName="strong">{`linked`}</em></strong>{` through connections not in Folders, sections and dividers.`}</li>
          <li parentName="ul">{`Suppose I know how to code a To-Do list manager. I have a certain skillset with which I can code it. Now, the skills that I used to make the To-Do List manager, are not limited to it. I can apply those skills to building many other applications such as a Note Taker for example. My skills of building the To-Do list manager are `}<strong parentName="li"><em parentName="strong">{`linked`}</em></strong>{` to the skills required for building a Note-Taker.`}</li>
          <li parentName="ul">{`As you can see that the skill of building a To-Do List manager is `}<strong parentName="li"><em parentName="strong">{`"linked"`}</em></strong>{`. This is the same thing that happens with Roam Research except in Roam Research, you link `}<strong parentName="li">{`knowledge`}</strong></li>
        </ul>
      </li>
    </ul>
    <h1>{`The main use case:`}</h1>
    <ul>
      <li parentName="ul">{`The main use case is Research s implied by the name. To do research, you don't have to be a PhD student and be trying to solve the very nature of reality. Anyone can research anything. You may be the sort of person who consumes a lot of content. Maybe, you like to read books, maybe you like to read articles or watch videos. You can call that research. This very article is being written by researching. Over few weeks, I have been using Roam, I have read countless articles, watched videos and `}<strong parentName="li">{`consumed`}</strong>{` a lot of content.`}</li>
      <li parentName="ul">{`While reseraching, I made sure to take notes on the things that I consumed. I made proper tags and pages for them also. This meant that I did not have to start from scratch which can be very intimidating at times. In roam, you can ^^shift-click^^ on any page link to open it in the sidebar. I have a page name "Roam Research" with which I tag everything that I consume regarding Roam. Now, I have opened that page in the side bar and I can site from those notes in order to write this article.`}</li>
    </ul>
    <h1>{`How to consume better to utilise Roam Research`}</h1>
    <ul>
      <li parentName="ul">{`In order to consume better with Roam, the first thing that you need to get is the `}<a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/roam-highlighter/hponfflfgcjikmehlcdcnpapicnljkkc"
        }}>{`Roam Highlighter`}</a>{`. The Roam Highlighter is a chrome-extension with which you can highlight articles and paste the highlights into Roam. `}<a parentName="li" {...{
          "href": "https://youtu.be/IZfsQy-2ssY"
        }}>{`Here`}</a>{` is a tutorial for using the Roam Highlighter.`}</li>
    </ul>
    <h1>{`Why Roam and not any other alternative?`}</h1>
    <ul>
      <li parentName="ul">{`The only main alternative to Roam Reserach(at least that I have heard off and used) is `}<a parentName="li" {...{
          "href": "https://obsidian.md/"
        }}>{`Obsidian`}</a>{`. I have used obsidian a lot and it is really great. One of the major features it lacks is block linking. In Roam, you can link blocks as well as pages but in obisian(to my knowledge at least) you can only link pages. If you have never used Roam before, then I will suggest that you first try out Obsidian and then if you life it switch to Roam.`}</li>
    </ul>
    <h1>{`A thought about pricing`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If you can not comfortably pay for the Roam Research price then, I will recommend that you do not take the free 14 day trial or even consume any content about it. You will get addicted to it. It is totally worth the money and I may even argue that it is under-priced but for some people being worth the money is simply not enough if you do not have enough money to afford it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can try out Roam Research `}<a parentName="p" {...{
            "href": "https://roamresearch.com/"
          }}>{`here`}</a>{`. It has a 14-Day free trial.`}</p>
      </li>
    </ul>
    <hr></hr>
    <h1>{`Further Reading`}</h1>
    <h2>{`These sources are free`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.shuomi.me/blog/roam-research-course-a-complete-guide"
        }}>{`Shu Omi`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.roambrain.com/"
        }}>{`Roam Brain`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reddit.com/r/RoamResearch/"
        }}>{`Roam Research subreddit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://forum.roamresearch.com/"
        }}>{`Roam Research Forum`}</a>
        <ul parentName="li">
          <li parentName="ul">{`These communities are very helpful`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nesslabs.com/?s=Roam+Research"
        }}>{`Ness Labs`}</a></li>
    </ul>
    <h2>{`These sources are paid`}</h2>
    <ul>
      <li parentName="ul">{`Nat Eliason’s `}<a parentName="li" {...{
          "href": "https://learn.nateliason.com/"
        }}>{`Effortless Output with Roam`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The course costs `}{`$`}{`99`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Ali Abdaal's `}<a parentName="li" {...{
          "href": "https://watchnebula.com/aliabdaal"
        }}>{`workflow series`}</a>
        <ul parentName="li">
          <li parentName="ul">{`It is available on `}<a parentName="li" {...{
              "href": "https://watchnebula.com/"
            }}>{`Nebula`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      